<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="获取途径" prop="pointsWay">
        <el-input
          v-model="dataForm.pointsWay"
          :disabled="disabled"
          placeholder="0——提前订餐，1——按日微信支付且花费超额，2——按月微信支付且花费超额"
        ></el-input>
      </el-form-item>
      <el-form-item label="花费金额" prop="playMoney">
        <el-input
          v-model="dataForm.playMoney"
          :disabled="disabled"
          placeholder="花费金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="获取积分时间" prop="pointsTime">
        <el-input
          v-model="dataForm.pointsTime"
          :disabled="disabled"
          placeholder="获取积分时间/订餐时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="积分量" prop="points">
        <el-input
          v-model="dataForm.points"
          :disabled="disabled"
          placeholder="积分量"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        pointsWay: '',
        playMoney: '',
        pointsTime: '',
        points: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/pointsgetlog/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.pointsgetlog;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/pointsgetlog/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
